// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-calendar-js": () => import("./../../../src/pages/calendar.js" /* webpackChunkName: "component---src-pages-calendar-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-media-js": () => import("./../../../src/pages/media.js" /* webpackChunkName: "component---src-pages-media-js" */),
  "component---src-pages-membership-js": () => import("./../../../src/pages/membership.js" /* webpackChunkName: "component---src-pages-membership-js" */),
  "component---src-pages-merchandise-js": () => import("./../../../src/pages/merchandise.js" /* webpackChunkName: "component---src-pages-merchandise-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-profile-js": () => import("./../../../src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */),
  "component---src-pages-results-js": () => import("./../../../src/pages/results.js" /* webpackChunkName: "component---src-pages-results-js" */),
  "component---src-pages-rugbyleague-js": () => import("./../../../src/pages/rugbyleague.js" /* webpackChunkName: "component---src-pages-rugbyleague-js" */),
  "component---src-pages-squad-js": () => import("./../../../src/pages/squad.js" /* webpackChunkName: "component---src-pages-squad-js" */),
  "component---src-pages-sv-index-js": () => import("./../../../src/pages/sv/index.js" /* webpackChunkName: "component---src-pages-sv-index-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-pages-training-js": () => import("./../../../src/pages/training.js" /* webpackChunkName: "component---src-pages-training-js" */),
  "component---src-templates-blogpost-js": () => import("./../../../src/templates/blogpost.js" /* webpackChunkName: "component---src-templates-blogpost-js" */)
}

